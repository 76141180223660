// Prepares Open Graph and Twitter metadata for AuthorMeta 

import _ from 'lodash'
import PropTypes from 'prop-types'

export const getAuthorProperties = (primaryAuthor) => {
    let authorProfiles = []

    const twitterHandle = primaryAuthor.twitter ? primaryAuthor.twitter.replace(/^@/, '') : null;
    const facebookHandle = primaryAuthor.facebook ? primaryAuthor.facebook.replace(/^\//, '') : null;

    const twitterUrl = twitterHandle ? `https://twitter.com/${twitterHandle}/` : null;
    const facebookUrl = facebookHandle ? `https://www.facebook.com/${facebookHandle}/` : null;

    authorProfiles.push(
        primaryAuthor.website ? primaryAuthor.website : null,
        twitterUrl,
        facebookUrl
    )

    authorProfiles = _.compact(authorProfiles)

    return {
        name: primaryAuthor.name || null,
        sameAsArray: authorProfiles.length ? `[${_.join(authorProfiles, ', ')}]` : null,
        image: primaryAuthor.profile_image || null,
        facebookUrl: facebookUrl,
    }
}

getAuthorProperties.defaultProps = {
    fetchAuthorData: false,
}

getAuthorProperties.propTypes = {
    primaryAuthor: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profile_image: PropTypes.string,
        website: PropTypes.string,
        twitter: PropTypes.string,
        facebook: PropTypes.string,
    }).isRequired,
}

export default getAuthorProperties
