// Prepares JSON-LD (schema.org), Open Graph (Facebook) and Twitter metadata for MetaData.js

import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import url from 'url'
import config from '../../../utils/siteConfig'
import ImageMeta from './ImageMeta'

const WebsiteMeta = ({ canonical, data, description, image, settings, title, type }) => {
    settings = settings.allGhostSettings.edges[0].node

    description = settings.description

    title = `${settings.title}`

    const publisherLogo = url.resolve(config.siteUrl, (settings.logo || config.siteIcon))

    let shareImage = image || data.feature_image || _.get(settings, `cover_image`, null)
    shareImage = shareImage ? url.resolve(config.siteUrl, shareImage) : null

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": type,
        url: canonical,
        image: shareImage ?
            {
                "@type": `ImageObject`,
                url: shareImage,
                width: config.shareImageWidth,
                height: config.shareImageHeight,
            } : undefined,
        publisher: {
            "@type": `Organization`,
            name: settings.title,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: 60,
                height: 60,
            },
        },
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl,
        },
        description,
    }

    const twitterHandle = settings.twitter.replace(/^@/, ``);

    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical} />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${twitterHandle}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600&display=swap" rel="stylesheet" />
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

// typecheck with PropTypes
WebsiteMeta.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        meta_title: PropTypes.string,
        meta_description: PropTypes.string,
        name: PropTypes.string,
        feature_image: PropTypes.string,
        description: PropTypes.string,
        bio: PropTypes.string,
        profile_image: PropTypes.string,
    }).isRequired,
    settings: PropTypes.shape({
        logo: PropTypes.object,
        description: PropTypes.string,
        title: PropTypes.string,
        twitter: PropTypes.string,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.oneOf([`WebSite`, `Series`]).isRequired,
}

// Get data from Ghost and render it through the above 
const WebsiteMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettingsWebsiteMeta {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <WebsiteMeta settings={data} {...props} />}
    />
)

export default WebsiteMetaQuery
